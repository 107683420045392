import { Injectable } from '@angular/core';
import { EOHttpClient } from './eohttp-client.service';
import { models } from '../globals/model-names';

@Injectable({
  providedIn: 'root'
})
export class LeadContactDataService {

  baseURL:string;

  constructor(private eoHttp:EOHttpClient) { 
    this.baseURL = '/api/'+models.LEAD_CONTACT_DATA;
  }

  getById(contactDataId){
    return this.eoHttp.get(this.baseURL + '/' + contactDataId);
  }

  getByLeadReceivedId(leadReceivedId){
    return this.eoHttp.get(this.baseURL,{lead: `/api/${models.LEAD_RECEIVED}/${leadReceivedId}`});
  }

  insert(contactData, leadIRI){
    let auxContactData = Object.assign({},contactData);
    auxContactData.lead = leadIRI;
    return this.eoHttp.post(this.baseURL, auxContactData);
  }

}
