import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }


  getParamsByState(stateName){
    var result = localStorage.getItem(stateName);
    if(result===null){
        return {};
    }
    return JSON.parse(result);
  };

  setParamsByState(params,stateName){
    return localStorage.setItem(stateName, JSON.stringify(params));
  };

  deleteParamsByState(stateName){
    localStorage.removeItem(stateName);
  };

  setLastHunterAssigned(hunterId){
    localStorage.setItem("LastHunterAssigned",hunterId);
  };

  getLastHunterAssigned(){
    return localStorage.getItem("LastHunterAssigned");
  };

  setLastIRPFGroup(group){
    localStorage.setItem("LastIRPFGroup",group);
  };

  getLastIRPFGroup(){
    return localStorage.getItem("LastIRPFGroup");
  };
}
