import { Injectable } from '@angular/core';
import { EOHttpClient } from './eohttp-client.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class QueuedLeadsService {

constructor(private eoHttp:EOHttpClient) {  }

    get(extraparams=null){
        return this.eoHttp.get('/api/queued_exportable_leads', extraparams);
    }

    create(leadId, type){
        return this.eoHttp.post('/api/queued_exportable_leads', {leadId, type});
    }

    delete(id){
        return this.eoHttp.delete('/api/queued_exportable_leads/'+id);
    }

}