import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { formatUrl, toHttpParams } from '../globals/utils';
import { EOHttpClient } from './eohttp-client.service';

@Injectable({
  providedIn: 'root'
})
export class OfficeService {

  constructor(private http:HttpClient, private eoHttp:EOHttpClient) { }

  getAllOffices(organization){
    return this.http.get(formatUrl('/Office/getAllByOrganization/'+organization.Id));
  };

  getAllOfficesByOrganization(organizationId){
    return this.eoHttp.get(`/api/organizations/${organizationId}/offices`);
  }

  getAllOfficesByPartner(partnerId){
    // return this.http.get(formatUrl('/Office/getAllByPartner/'+partner.Id));
    return this.eoHttp.get(`/api/partners/${partnerId}/offices`);
  }

  manageOffices(partnerId, officesSelected, officesBefore){
    let officesToAdd = officesSelected.filter(specialty=>officesBefore.indexOf(specialty.Id)==-1)
    let officesToRemove = officesBefore.filter(specialty=>officesSelected.indexOf(specialty.Id)==-1)
    let promises = [];
    if(officesToAdd.length>0){
      for(let office of officesToAdd){
        promises.push(this.addPartnerToOffice(office, partnerId).toPromise());
      }
    }
    if(officesToRemove.length>0){
      for(let office of officesToRemove){
        promises.push(this.removePartnerToOffice(office, partnerId).toPromise());
      }
    }
    if(promises.length==0){
      return Promise.resolve(true);
    }
    return Promise.all(promises);
  }

  insertOffice(office){
    var address = office.Address;
    for (var key in address) {
        office[key] = address[key];
    }
    let body = toHttpParams(office);
    return this.http.post(formatUrl('/Office/create'),body);
  }

  deleteOffice(id){
    return this.http.get(formatUrl('/Office/delete/'+id));
  };

  addPartnerToOffice(officeId, partnerId){
    return this.http.get(formatUrl('/Office/addPartner/'+officeId+'/'+partnerId));
  };

  removePartnerToOffice(officeId, partnerId){
      return this.http.get(formatUrl('/Office/removePartner/'+officeId+'/'+partnerId));
  };

}
