import { Subscription, interval } from "rxjs";

export class Timer {

  timerInterval:Subscription;
  hurry:boolean = false;
  timerString:string = '--:--'

  constructor(){}

  startTimer(){
    this.timerInterval = interval(1000).subscribe(val=>{
      let minutes = Math.floor((val+1)/60);
      let seconds = (val+1) % 60;
      if(minutes >= 3){
        this.hurry=true;
      }
      this.timerString = ('0'+minutes).slice(-2)+':'+('0'+seconds).slice(-2);
    })
  }

  stopTimer(){
    if(this.timerInterval){
      this.timerInterval.unsubscribe();
    }
    this.timerString = '--:--';
  }

}