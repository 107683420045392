import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as utils from 'src/app/globals/utils';
import { EOHttpClient } from './eohttp-client.service';

@Injectable({
  providedIn: 'root'
})
export class LeadEmailNotificationService {

  constructor(private http:HttpClient, private eoHttp:EOHttpClient) { }

  createUnableToContact(leadId){
    return this.eoHttp.post('/api/leads_received/missed_contact_notification/'+leadId, {});
  }

  createWrongNumber(id,adminId){
      var body = utils.toHttpParams({
          LeadId: id,
          AdminId: adminId
      });
      return this.http.post(utils.formatUrl('/LeadEmailNotification/createWrongNumber'),body);
  };
}
